export default [
  {
    name: 'login',
    path: '/login',
    component: () => import('@/pages/Login'),
    meta: {
      public: true,  // Allow access to even if not logged in
      onlyWhenLoggedOut: true
    }
  },
  {
    name: '404',
    path: '*',
    component: () => import('@/layouts/404.vue')
  },
  {
    name: '401',
    path: '/401',
    component: () => import('@/layouts/401.vue')
  },
  {
    path: '/',
    component: () => import('@/layouts/Main'),
    children: [
      {
        name: 'home',
        path: '/',
        redirect: '/dashboard'
      },
      {
        name: 'dashboard',
        path: '/dashboard',
        components: {
          default: () => import('@/pages/Dashboard'),
          task: () => import('@/components/tasks/TaskEditModal')
        }
      },
      {
        name: 'map',
        path: '/map',
        components: {
          default: () => import('@/pages/Map'),
          task: () => import('@/components/tasks/TaskEditModal')
        }
      },
      {
        name: 'map',
        path: '/map/:latlng',
        components: {
          default: () => import('@/pages/Map'),
          task: () => import('@/components/tasks/TaskEditModal')
        }
      },
      {
        name: 'calendar',
        path: '/calendar',
        components: {
          default: () => import('@/pages/Calendar'),
          task: () => import('@/components/tasks/TaskEditModal')
        }
      },
      {
        name: 'tasks',
        path: '/tasks',
        components: {
          default: () => import('@/pages/Tasks'),
          task: () => import('@/components/tasks/TaskEditModal')
        },
      },
      {
        name: 'account',
        path: '/account',
        components: {
          default: () => import('@/pages/Account')
        },
      },
      {
        name: 'report',
        path: '/preview-report/:id',
        components: {
          default: () => import('@/pages/Report')
        },
      },
      {
        name: 'users',
        path: '/users',
        components: {
          default: () => import('@/pages/Users')
        },
      },
      {
        name: 'user',
        path: '/users/:id',
        components: {
          default: () => import('@/components/users/UserEditForm')
        },
      },
      { name: 'states',        path: '/states',            component: require('@/pages/States.vue').default },
      { name: 'statesedit',    path: '/states/:sta_id',    component: require('@/components/states/StatesForm.vue').default },
      { name: 'types',         path: '/types',             component: require('@/pages/Types.vue').default },
      { name: 'typesedit',     path: '/types/:typ_id',     component: require('@/components/types/TypeForm.vue').default },
    ]
  },
  {
    name: 'forbidden',
    path: '/forbidden',
    component: () => import('@/layouts/403.vue')
  }
]

      /*
      { name: 'users', path: '/users', component: require('./pages/archives/Users.vue').default },
      { name: 'useredit', path: '/users/:usr_id', component: require('./pages/archives/User_edit.vue').default },

      { name: 'states', path: '/states', component: require('./pages/archives/States.vue').default },
      { name: 'statesedit', path: '/states/:sta_id', component: require('./pages/archives/States_edit.vue').default },
      { name: 'types', path: '/types', component: require('./pages/archives/Types.vue').default },
      { name: 'typesedit', path: '/types/:typ_id', component: require('./pages/archives/Type_edit.vue').default },
      */
