import ApiService from '@/services/api'
import dayjs from 'dayjs'
import status from '@/services/status'

const getLateTasksCount = (tasks) => {
  const lateTasks = tasks.filter(t => t.tsk_sta_id === status.ACCEPTED && dayjs(t.tsk_started_at).isBefore(dayjs()))
  return lateTasks?.length || 0
}

const getSoonTasksCount = (tasks) => {
  const soonTasks = tasks.filter(t => {
    return t.tsk_sta_id === status.ACCEPTED
      && dayjs(t.tsk_started_at).isAfter(dayjs())
      && dayjs().add(process.env.VUE_APP_NB_DAYS_WARNING, 'days').isAfter(dayjs(t.tsk_started_at))
  })
  return soonTasks?.length || 0
}

const getScheduledTasksCount = (tasks) => {
  const date = dayjs().add(process.env.VUE_APP_NB_DAYS_WARNING, 'days')
  const scheduledTasks = tasks.filter(t => t.tsk_sta_id === status.ACCEPTED && dayjs(t.tsk_started_at).isAfter(date))
  return scheduledTasks?.length || 0
}

const getNotFinishedTasksCount = (tasks) => {
  return tasks.filter(t => t.tsk_sta_id === status.ACCEPTED).length || 0
}

const state = () => {
  return {
    districts: null
  }
}

const mutations = {
  SET_DISTRICTS (state, districts) {
    state.districts = districts
  }
}

const actions = {
  async fetchDistricts ({ commit }) {
    const { data } = await ApiService.get('/areas')
    commit('SET_DISTRICTS', data)
    return data
  }
}

const getters = {
  districts (state) {
    if (state?.districts?.features) {
      return state.districts.features.map(d => {
        d.meta.counts = {
          late: getLateTasksCount(d.meta.tasks),
          soon: getSoonTasksCount(d.meta.tasks),
          scheduled: getScheduledTasksCount(d.meta.tasks),
          notFinished: getNotFinishedTasksCount(d.meta.tasks)
        }
        return d
      })
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
