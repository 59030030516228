import axios from 'axios'
// import { TokenService } from '@/services/storage'
// import { actions } from "@/store/auth.module";
import store from '@/store'
import router from '@/plugins/router'

const ApiService = {

  // Stores the 401 interceptor position so that it can be later ejected when needed
  _401interceptor: null,

  init(baseURL, timeout) {
    axios.defaults.baseURL = baseURL;
    ApiService.setHeader()
    if (timeout !== undefined && timeout > 0) {
      axios.defaults.timeout = timeout;
    }
  },

  setHeader() {
    axios.defaults.headers.common["Authorization"] = `Bearer ${store.getters['user/accessToken']}`
  },

  removeHeader() {
    axios.defaults.headers.common = {}
  },

  get(resource) {
    return this.data(axios.get(resource))
  },

  post(resource, data) {
    return this.data(axios.post(resource, data))
  },

  upload(resource, data) {
    const config = {
      headers: {
        ...axios.defaults.headers,
        'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
        'X-Requested-With': 'XMLHttpRequest'
      }
    }
    const formData = new FormData()
    Object.keys(data).forEach(key => {
      formData.append(key, data[key])
    })
    return this.data(axios.post(resource, formData, config))
  },

  put(resource, data) {
    return this.data(axios.put(resource, data))
  },

  delete(resource) {
    return this.data(axios.delete(resource))
  },

  data(promise) {
    return promise.then(response => response?.data)
  },

  /**
   * Perform a custom Axios request.
   *
   * data is an object containing the following properties:
   *  - method
   *  - url
   *  - data ... request payload
   *  - auth (optional)
   *    - username
   *    - password
   **/
  customRequest(data, wrap = false) {
    if (wrap) {
      return this.data(axios(data))
    } else {
      return axios(data)
    }
  },

  mount401Interceptor() {
    this._401interceptor = axios.interceptors.response.use(
      (response) => {
        return response
      },
      async (error) => {
        if (!store.getters['user/refreshToken']) {
          await router.push('/login').catch(() => {})
        }
        if (error.request.status === 401) {
          if (error.config.url.includes('/logout')) {
            throw error
          } else if (error.config.url.includes('/accessToken')) {
            // Refresh token has failed. Logout the user
            // await store.dispatch('user/logout')
            await router.push('/login').catch(() => {})
            throw error
          } else {
            // Refresh the access token
            try {
              //const response = 
              await store.dispatch('user/refreshAccessToken')
              //this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text: 'REFRESH TOKEN here' + response })
              // Retry the original request
              // return this.customRequest({
              //   method: error.config.method,
              //   url: error.config.url,
              //   data: error.config.data
              // })
            } catch (e) {
              // Refresh has failed - reject the original request
              throw error
            }
          }
        }

        // If error was not 401 just reject as is
        throw error
      }
    )
  },

  unmount401Interceptor() {
    // Eject the interceptor
    axios.interceptors.response.eject(this._401interceptor)
  }
}

export default ApiService
