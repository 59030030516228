export default {
    tasks: {
      create: ['ADMIN', 'CONDUCTEUR', 'CHEFEQUIPE'],
      update: ['ADMIN', 'CONDUCTEUR', 'CHEFEQUIPE'],
      read:   ['ADMIN', 'CONDUCTEUR', 'CHEFEQUIPE', 'INTERVENTION', 'CONSULTATION', 'SCE_CHIEFUNIT', 'SCE_MANAGER', 'SCE_ASSISTANT'],
      delete: ['ADMIN', 'CONDUCTEUR'],
      createCustom: ['INTERVENTION', 'SCE_CHIEFUNIT', 'SCE_MANAGER', 'SCE_ASSISTANT']
    },
    time: {
      create: ['ADMIN', 'CONDUCTEUR', 'CHEFEQUIPE', 'AGENT'],
      update: ['ADMIN', 'CONDUCTEUR', 'CHEFEQUIPE', 'AGENT'],
      read:   ['ADMIN', 'CONDUCTEUR', 'CHEFEQUIPE'],
      delete: ['ADMIN', 'CONDUCTEUR'],
    },
    staffmanagement: {
      create: ['ADMIN', 'CONDUCTEUR', 'CHEFEQUIPE', 'AGENT'],
      update: ['ADMIN', 'CONDUCTEUR', 'CHEFEQUIPE', 'AGENT'],
      read:   ['ADMIN', 'CONDUCTEUR', 'CHEFEQUIPE'],
      delete: ['ADMIN', 'CONDUCTEUR'],
    },
    documents : {
      create: ['ADMIN', 'CONDUCTEUR', 'CHEFEQUIPE', 'AGENT', 'SCE_CHIEFUNIT'],
      update: ['ADMIN', 'CONDUCTEUR', 'CHEFEQUIPE', 'AGENT'],
      read:   ['ADMIN', 'CONDUCTEUR', 'CHEFEQUIPE'],
      edit:   ['ADMIN', 'CONDUCTEUR', 'CHEFEQUIPE'],
      delete: ['ADMIN', 'CONDUCTEUR', 'CHEFEQUIPE']
    },
    alerts : {
      create: ['ADMIN', 'CONDUCTEUR', 'CHEFEQUIPE', 'INTERVENTION', 'CONSULTATION'],
      update: ['ADMIN', 'CONDUCTEUR', 'CHEFEQUIPE', 'INTERVENTION', 'CONSULTATION'],
      read:   ['ADMIN', 'CONDUCTEUR', 'CHEFEQUIPE', 'INTERVENTION', 'CONSULTATION'],
      edit:   ['ADMIN', 'CONDUCTEUR', 'CHEFEQUIPE', 'INTERVENTION', 'CONSULTATION'],
      delete: ['ADMIN', 'CONDUCTEUR', 'CHEFEQUIPE', 'INTERVENTION', 'CONSULTATION']
    },
}
