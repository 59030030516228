import Vue from 'vue'
import App from '@/App'
import store from '@/store'

// plugins (some of them change the Vue prototype directly)
import vuetify from './plugins/vuetify'
import router from '@/plugins/router'
import '@/plugins/download'
import '@/plugins/dayjs'
import '@/plugins/apexchart'
import '@/plugins/api'
import '@/plugins/build'
import '@/plugins/leaflet'

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
