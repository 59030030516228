import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import user from '@/store/modules/user'
import app from '@/store/modules/app'
import tasks from '@/store/modules/tasks'
import map from '@/store/modules/map'

Vue.use(Vuex)

// vuex-persist
const APP_KEY = require('../../package.json').appKey
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: APP_KEY
})

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    user,
    app,
    tasks,
    map
  },
  plugins: [vuexLocal.plugin],
  strict: debug
})
