const beforeEachMiddleware = async (to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public)
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
  const loggedIn = this.$store.getters['user/accessToken']

  if (!isPublic && !loggedIn) {
    return next({ path: '/login', query: { redirect: to.fullPath } })  // Store the full path to redirect the user to after login
  }
  if (loggedIn && onlyWhenLoggedOut) {
    return next('/') // Do not allow user to visit login page or register page if they are logged in
  }
  const can = await this.$store.dispatch('user/canViewPage', to.name)
  if (!can) {
    return next('/forbidden')
  }

  next()
}

export { beforeEachMiddleware }
