<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-0" flat color="barrinfo">
        <v-toolbar-title :class="contextStyle.titleClass" color="barrtextinfo">
            <v-breadcrumbs large divider=">" :items="contextStyle.breadcrumbs" :class="'pl-0'">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">{{ item.text }}</v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs12 class="pa-2">
            <v-text-field label="Nom" v-model="form.typ_label"></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select :items="entityTable" v-model="form.typ_entity" 
                item-text="name" item-value="name" label="Statut" single-line :disabled="modify"
                @change="form.typ_id = entityTable.find(x => x.name === form.typ_entity).id" >
            </v-select>
            <!-- <v-text-field label="Entité"  v-model="form.typ_entity" :disabled="modify"></v-text-field> -->
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select :items="visibility" item-value="value" v-model="form.typ_visibility" label="Statut" single-line></v-select>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Identifiant type"    v-model="form.typ_id" :disabled="modify" ></v-text-field>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-text-field label="Icone"    v-model="form.typ_icon" ></v-text-field>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-menu offset-y content-class="elevation-0" style="z-index:900">
                <template v-slot:activator="{ on }">
                    <v-btn :color="form.typ_color" block dark class="mt-3" v-on="on">Couleur</v-btn>
                </template>
                <v-color-picker v-model="form.typ_color" class="ma-2 elevation-0" hide-canvas :swatches="swatches" show-swatches></v-color-picker>
            </v-menu>
        </v-flex>
        <v-flex xs12 class="pa-2">
            <v-textarea label="Description" rows="2" v-model="form.typ_description" ></v-textarea>
        </v-flex>
        </v-layout>
    </v-card>
    <div class="text-right mt-3 mr-3">
        <v-btn rounded text href="/#/types/">
            <v-icon left dark>mdi-close</v-icon>Annuler
        </v-btn>
        <v-btn rounded color="success" class="ml-3" :loading="saveProgress" @click.native="saveForm()" :disabled="saveProgress || disableForm">
            <v-icon left dark>mdi-content-save-outline</v-icon>Enregistrer
        </v-btn>
    </div>
</v-container>
</template>

<script>
import { cacheGetters, cacheMutations } from "@/store/cache.module"
import { mapGetters } from 'vuex'

export default {
    name: 'typesedit',
    props: ['toast', 'contextStyle'],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            visibility: [
                {'value': 1,    'text': 'Visible'},
                {'value': 0,    'text': 'Désactivé'}
            ],
            modify: false,
            form:{
              typ_id:'', typ_tag: '', typ_entity: '',
              typ_label: '', typ_visibility: 1, typ_color: '#000000', typ_note: '',
              typ_created_at: '', typ_updated_at: '', typ_sta_id: 'GEN01'
            },
            swatches: [
                ['#FF0000', '#AA0000', '#550000'], ['#FFFF00', '#AAAA00', '#555500'], ['#00FF00', '#00AA00', '#005500'],
                ['#00FFFF', '#00AAAA', '#005555'], ['#0000FF', '#0000AA', '#000055'],
            ],
        }
    },
    computed: {
        ...mapGetters({
            typeState: 'app/typesById',
            stateState: 'app/statesById',
        }),
        entityTable() {
            let entities  = []
            let entObject = {}
            let types     = {} //this.getCacheType()
            for (const [key, value] of Object.entries(this.typeState)) {
                let group = key.substring(0, 3)
                if( !types[group] ){ types[group] = {} }
                types[group][key] = value
            }
            for( let group in types ){
                for( let type in types[group] ){
                    let typNum = ( parseInt( types[group][type].typ_id.substring(3, 5) ) + 1 ) + ""
                    let typId  = types[group][type].typ_id.substring(0, 3) + typNum.padStart(2, '0')
                    entObject[group] = { id: typId, name: types[group][type].typ_id.substring(0, 3) }
                }
            }
            for( let entity in entObject ){
                entities.push( entObject[entity] )
            }
            return entities
        },
        disableForm(){
            return !(this.form.typ_entity.length)
        }
    },
    mounted:function (){
        if( ( this.$route.params.typ_id ) && this.$route.params.typ_id.length > 3 ){
            this.modify = true
            this.getForm()

        }
    },
    methods: {
        getForm(){
            return new Promise( (resolve, reject) => {
                this.$http.get( '/types/' + this.$route.params.typ_id )
                .then( (response) => {
                    var prop
                    for( prop in response.data ){
                        if( this.form[prop] !== undefined ){
                            this.form[prop] = response.data[prop] 
                        }
                    }
                    resolve('resolved')
                }).catch( (error) => {
                    this.$root.$toast({ color: 'warning', text: error })
                    reject('rejected')
                })
            })
        },
        saveForm () {
            // Enregistrement du formulaire
            this.saveProgress = true
            this.form.typ_tag  = this.form.typ_id.substring(0, 3)
            if( this.$route.params.typ_id.length > 3 ){
                this.$http.put( '/types/' + this.form.typ_id, this.form )
                .then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.UpdateCacheTypes()
                    this.$router.push({ path: '/types' })
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                })
            } else {
                this.$http.post( '/types/', this.form )
                .then( () => {
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/types' })
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                })
            }
        },
        UpdateCacheTypes () {
            return new Promise( (resolve, reject) => {
                this.$http.get( '/types?per_page=-1' ).then( (response) => {
                let types = {}
                response.data.data.forEach( (item) => {
                    if( types[ item.typ_id.substring(0, 3) ] == undefined ){
                    types[ item.typ_id.substring(0, 3) ] = {}
                    }
                    types[ item.typ_id.substring(0, 3)][item.typ_id] = item
                })
                cacheMutations.setTypes( types )
                resolve('resolved')
                }).catch( () => { 
                reject('failed')
                })
            })
        },
        getCacheType: cacheGetters.getTypes,
    },
    components: { }
}
</script>