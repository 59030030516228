import Vue from 'vue'
import dayjs from 'dayjs'

// Object.defineProperties(Vue.prototype, {
//     $date: {
//         get() {
//             return dayjs
//         }
//     }
// });

Object.defineProperty(Vue.prototype, '$date', { value: dayjs })
