import Vue from "vue";
import Storages from 'js-storage'

export const cacheData = Vue.observable({
  user: {},
  states: {},
  types: {},
  usrFilter: {},
  searchBar: {},
  bookmark: {},

  taskType: [],
  session: [],

})

export const cacheGetters = {
  getStates: (group, id) => {
    if (group !== undefined && cacheData.states[group] !== undefined) {
      if (id !== undefined) {
        return (cacheData.states[group][id] !== undefined) ? cacheData.states[group][id] : { typ_label: 'Non définit' };
      } else {
        let stateType = []
        for (let prop in cacheData.states[group]) {
          stateType.push(cacheData.states[group][prop])
        }
        return stateType
      }
    } else {
      return cacheData.states
    }
  },
  getTypes: (group, id) => {
    if (group !== undefined && cacheData.types[group] !== undefined) {
      if (id !== undefined) {
        return (cacheData.types[group][id] !== undefined) ? cacheData.types[group][id] : {
          typ_name: 'Non définit',
          typ_tag: 'ND'
        };
      } else {
        let tabType = []
        for (let prop in cacheData.types[group]) {
          tabType.push(cacheData.types[group][prop])
        }
        return tabType
        //return cacheData.types[group]
      }
    } else if (group !== undefined) {
      let tabType = []
      return tabType
    } else {
      return cacheData.types
    }
  },
  getTypesByTag: (group, tag) => {
    let tabType = []
    if (group.length) {
      for (let prop in cacheData.types[group]) {
        if (cacheData.types[group][prop].typ_tag == tag) {
          tabType.push(prop)
        }
      }
    } else {
      for (let group in cacheData.types) {
        for (let prop in cacheData.types[group]) {
          if (cacheData.types[group][prop].typ_tag == tag) {
            tabType.push(prop)
          }
        }
      }
    }
    return tabType
  },
  getTypesByGroup: (group) => {
    let tabType = []
    let groupTab = group.split(",")
    groupTab.array.forEach(element => {
      for (let prop in cacheData.types[element]) {
        tabType.push(cacheData.types[element][prop])
      }
    })
    return tabType
  },
  getUser: () => {
    return cacheData.user
  },
  getUserRole: () => {
    return cacheData.user.usr_role
  },
  getFilter: () => {
    // default value
    cacheData.usrFilter = (!cacheData.usrFilter) ? {} : cacheData.usrFilter
    if (!cacheData.usrFilter.tsk_sta_id) {
      cacheData.usrFilter.tsk_sta_id = ['GEN01']
      cacheData.usrFilter.tsk_typ_id = []
      cacheData.usrFilter.min_tsk_date_plan = null
      cacheData.usrFilter.max_tsk_date_plan = null
    }
    return cacheData.usrFilter
  },
  getFiltrerFromStorage() {
    if (Storages.localStorage.isSet(Vue.prototype.$APP_COD + "_cache")) {
      let cache = Storages.localStorage.get(Vue.prototype.$APP_COD + "_cache")
      return cache.usrFilter || {}
    } else {
      return {}
    }
  },
  getSearchBar: () => {
    return cacheData.searchBar
  },
  getSearchBarItem: (item) => {
    if (cacheData.searchBar !== undefined) {
      return cacheData.searchBar[item]
    } else {
      return ''
    }
  },
  getBookmark: () => {
    return cacheData.bookmark
  },
  getTaskType: () => {
    return cacheData.taskType
  },
  getSession: () => {
    return cacheData.session
  },
}

export const cacheActions = {
  async initCacheFromStorage() {
    new Promise((resolve) => {
      if (Storages.localStorage.isSet(Vue.prototype.$APP_COD + "_cache")) {
        let cache = Storages.localStorage.get(Vue.prototype.$APP_COD + "_cache")
        cacheMutations.setStates(cache.states)
        cacheMutations.setTypes(cache.types)
        cacheMutations.setUser(cache.user || {})
        cacheMutations.setFilter(cache.usrFilter || {})
        cacheMutations.setBookmark(cache.bookmark || {})
        cacheMutations.setSession(cache.session || [])
      }
      resolve('resolved')
    })
  },
  async storeCacheToStorage() {
    new Promise((resolve) => {
      Storages.localStorage.set(Vue.prototype.$APP_COD + "_cache", cacheData)
      resolve('resolved')
    })
  }
}

export const cacheMutations = {
  setStates(states) {
    cacheData.states = states;
  },
  setTypes(types) {
    cacheData.types = types;
  },
  setUser(user) {
    cacheData.user = user;
  },
  setFilter(usrFilter) {
    cacheData.usrFilter = usrFilter;
  },
  setSearchBar(searchBar) {
    cacheData.searchBar = searchBar;
  },
  setBookmark(bookmark) {
    cacheData.bookmark = bookmark;
  },
  setSearchBarItem(item, value) {
    cacheData.searchBar[item] = value;
  },
  setTaskType(taskType) {
    cacheData.taskType = taskType;
  },
  setSession(session) {
    cacheData.session = session;
  },

}
