import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

// Translation provided by Vuetify (javascript)
import francais from 'vuetify/es5/locale/fr'

const opts = {
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#005D7F',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#ff8307',
        // task state
        scheduled: '#6399f1',
        asked: '#f3e828',
        finished: '#58ea70',
        barrinfo:  '#E5E5E5',
      }
    }
  },
  lang: {
    locales: { francais },
    current: 'francais',
  },
}

export default new Vuetify(opts)
