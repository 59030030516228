import ApiService from '@/services/api'
import { taskUpdateService } from '@/rxjsServices';

const state = () => {
  return {
    task: null
  }
}

const mutations = {
  SET_TASK (state, task) {
    state.task = task
  },
  REMOVE_TASK (state) {
    state.task = null
  }
}

const actions = {
  async fetchTasks (state, filter) {
    filter = filter || ''
    const { data } = await ApiService.get('/tasks/' + filter)
    return data
  },
  async fetchTasksByArea (context, id) {
    const { data } = await ApiService.get(`/areas/${id}/tasks`)
    return data
  },
  async fetchTask (context, id) {
    const { data } = await ApiService.get(`/tasks/${id}/?include=area`)
    return data
  },
  async createTask (context, task) {
    const { data } = await ApiService.post('/tasks', task)
    taskUpdateService.sendTask('create')
    return data
  },
  async updateTask (context, task) {
    const { data } = await ApiService.put(`/tasks/${task.id}`, task)
    taskUpdateService.sendTask('update')
    return data
  },
  async closeTask (context, id) {
    const { data } = await ApiService.post(`/tasks/${id}/close`)
    taskUpdateService.sendTask('update')
    return data
  },
  async acceptTask (context, id) {
    const { data } = await ApiService.post(`/tasks/${id}/accept`)
    taskUpdateService.sendTask('update')
    return data
  },
  async uploadTaskFile (context, { task, picture }) {
    const { data } = await ApiService.upload(`/tasks/${task.id}/upload`, { picture })
    return data
  }
}

export default {
  namespaced: true,
  actions,
  state,
  mutations
}
