import Vue from 'vue'
import ApiService from '@/services/api'
import { TokenService } from '@/services/storage'

ApiService.init(process.env.VUE_APP_API_URL)
if (TokenService.getToken()) {
  ApiService.setHeader()
}
ApiService.mount401Interceptor()
Vue.prototype.$http = ApiService
