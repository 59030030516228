import ApiService from '@/services/api'

const state = () => {
  return {
    types: [],
    states: [],
    filter: {},
    versions: []
  }
}

const mutations = {
  SET_VERSIONS (state, versions) {
    state.versions = versions
  },
  SET_TYPES (state, types) {
    state.types = types
  },
  SET_STATES (state, states) {
    state.states = states
  },
  SET_FILTER (state, filter) {
    state.filter = filter
  },
  RESET_FILTER (state) {
    state.filter = {
      start: '',
      end: '',
      actions: [],
      custom: [],
      states: []
    }
  }
}

const actions = {
  async fetchTypes({ commit }) {
    const { data } = await ApiService.get('/types')
    commit('SET_TYPES', data)
    return data
  },
  async fetchStates({ commit }) {
    const { data } = await ApiService.get('/states')
    commit('SET_STATES', data)
    return data
  },
  async fetchVersions({ commit }) {
    const { data } = await ApiService.get('/versions')
    commit('SET_VERSIONS', data)
    return data
  }
}

const getters = {
  types (state) {
    return state.types
  },
  typesById (state) {
    return state.types.reduce((obj, item) => Object.assign(obj, { [item.typ_id]: item }), {})
  },
  states (state) {
    return state.states
  },
  statesById (state) {
    return state.states.reduce((obj, item) => Object.assign(obj, { [item.sta_id]: item }), {})
  },
  versions (state) {
    return state.versions
  },
  filter (state) {
    const hasDatesCondition = state.filter?.start || state.filter?.end
    const hasActionsCondition = state.filter?.actions?.length
    const hasCustomCondition = state.filter?.custom?.length
    const hasStateCondition = state.filter?.states?.length
    if (hasDatesCondition || hasActionsCondition || hasCustomCondition || hasStateCondition) {
      return state.filter
    } else {
      return false
    }
  },
  filterToUrl (state) {
    const hasDatesCondition   = state.filter?.start || state.filter?.end
    const hasDatesStart       = state.filter?.start
    const hasDatesEnd       = state.filter?.end
    const hasActionsCondition = state.filter?.actions?.length
    const hasCustomCondition = state.filter?.custom?.length
    const hasStateCondition = state.filter?.states?.length
    if (hasDatesCondition || hasActionsCondition || hasCustomCondition || hasStateCondition) {
      let filter  = []
      hasDatesStart && filter.push('min_tsk_started_at=' + state.filter.start)
      hasDatesEnd && filter.push('max_tsk_started_at=' + state.filter.end)
      hasActionsCondition && filter.push('tkt_typ_id=' + state.filter.actions.join(','))
      hasCustomCondition && filter.push('tsk_custom=' + state.filter.custom.join(','))
      hasStateCondition && filter.push('tsk_sta_id=' + state.filter.states.join(','))

      return encodeURI('?' + filter.join('&')) + '&extended=withtasktype'
    } else {
      return ''
    }
  },
  filterToPost (state) {
    const hasDatesCondition   = state.filter?.start || state.filter?.end
    const hasDatesStart       = state.filter?.start
    const hasDatesEnd       = state.filter?.end
    const hasActionsCondition = state.filter?.actions?.length
    const hasCustomCondition = state.filter?.custom?.length
    const hasStateCondition = state.filter?.states?.length
    let filter  = {}
    filter['extended'] = 'withtasktype'
    if (hasDatesCondition || hasActionsCondition || hasCustomCondition || hasStateCondition) {
      if(hasDatesStart)      { filter['min_tsk_started_at'] = state.filter.start }
      if(hasDatesEnd)        { filter['max_tsk_started_at'] = state.filter.end }
      if(hasActionsCondition){ filter['tkt_typ_id']         = state.filter.actions.join(',') }
      if(hasCustomCondition) { filter['tsk_custom']         = state.filter.custom.join(',') }
      if(hasStateCondition)  { filter['tsk_sta_id']         = state.filter.states.join(',') }

      return filter
    } else {
      return filter
    }
  },
  conditions (state) {
    const conditions = []
    conditions.push(state.filter?.start || state.filter?.end)
    conditions.push(state.filter?.actions?.length)
    conditions.push(state.filter?.custom?.length)
    conditions.push(state.filter?.states?.length)
    return conditions.filter(Boolean).length || 0
  },
  statesChoices (state) {
    const choices = ['STA01', 'STA03', 'STA05']
    return state.states.filter((s) => choices.includes(s.sta_id))
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
