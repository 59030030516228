<template>
  <router-view></router-view>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  async mounted() {
    await this.loadAppData()
  },
  methods: {
    async loadAppData () {
      try {
        this.loading = true
        await this.fetchTypes()
        await this.fetchStates()
        await this.fetchVersions()
        await this.buildPermissionsTable()
      } catch (err) {
        switch (err?.response?.status) {
          case 401:
            break
          default:
            this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text: err })
        }
      } finally {
        this.loading = false
      }
    },
    ...mapActions({
      fetchTypes: 'app/fetchTypes',
      fetchStates: 'app/fetchStates',
      fetchVersions: 'app/fetchVersions',
      buildPermissionsTable: 'user/buildPermissionsTable'
    })
  }
}
</script>

<style lang="scss">
@import './assets/leaflet.css';
@import './styles/variables.scss';
</style>
