import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/services/routes'
import { beforeEachMiddleware } from '@/services/router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  routes: routes
})

// apply middlewares
router.beforeEach = beforeEachMiddleware

export default router
