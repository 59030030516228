import permissions from '@/services/permissions'
import ApiService from '@/services/api'
import UserService from '@/services/user'

const ROLES = [
  'ADMIN',
  'CONDUCTEUR', // [CREATE, EDIT, VIEW]
  'CHEFEQUIPE', // CHEF EQUIPE [EDIT, VIEW]
  'AGENT', // utilisé pour association avec tâche uniquement
  'INTERVENTION', // (Externe) [CREATE, VIEW] (si creation => prestation spéciale)
  'CONSULTATION', // (Externe) [VIEW]
  'SCE_CHIEFUNIT',
  'SCE_MANAGER',
  'SCE_ASSISTANT',

]

const state = () => {
  return {
    accessToken: null,
    refreshToken: null,
    user: null,
    roles: ROLES,
    permissions: null
  }
}

const mutations = {
  SET_ACCESS_TOKEN (state, token) {
    state.accessToken = token
  },
  REMOVE_ACCESS_TOKEN (state) {
    state.accessToken = null
  },
  SET_REFRESH_TOKEN (state, token) {
    state.refreshToken = token
  },
  REMOVE_REFRESH_TOKEN (state) {
    state.refreshToken = null
  },
  SET_USER (state, user) {
    state.user = user
  },
  SET_PERMISSIONS_TABLE (state, table) {
    state.permissions = table
  }
}

const actions = {
  async login ({ commit }, { login, password }) {
    const requestData = {
      method: 'post',
      url: '/accessToken',
      data: {
        client_id: 2,
        client_secret: process.env.VUE_APP_OAUTH_KEY,
        grant_type: 'password',
        username: login,
        password: password
      }
    }
    const response = await ApiService.customRequest(requestData, true)
    commit('SET_ACCESS_TOKEN', response.access_token)
    commit('SET_REFRESH_TOKEN', response.refresh_token)
    ApiService.setHeader()
    return response
  },
  async logout ({ commit }) {
    await UserService.logout()
    commit('REMOVE_ACCESS_TOKEN')
    commit('REMOVE_REFRESH_TOKEN')
  },
  async refreshAccessToken ({ commit, state }) {
    const requestData = {
      method: 'post',
      url: '/accessToken',
      data: {
        client_id: 2,
        client_secret: process.env.VUE_APP_OAUTH_KEY,
        grant_type: 'refresh_token',
        refresh_token: state.refreshToken
      }
    }
    const response = await ApiService.customRequest(requestData, true)
    commit('SET_ACCESS_TOKEN', response.access_token)
    commit('SET_REFRESH_TOKEN', response.refresh_token)
  },
  async fetchMe ({ commit }) {
    const response = await ApiService.get('/users/me')
    commit('SET_USER', response)
    return response
  },
  async fetchUsers (context, params) {
    params      = params || {}
    let options = ''
    for( let prop in params ){
      options += ( !options.length ) ? '?' : '&'
      options += prop + '=' + params[prop]
    }
    options = '?per_page=false'

    const { data } = await ApiService.get('/users/' + options)
    return data
  },
  async fetchUser (context, id) {
    const { data } = await ApiService.get(`/users/${id}`)
    return data
  },
  async createUser (context, user) {
    const { data } = await ApiService.post('/users', user)
    return data
  },
  async updateUser (context, user) {
    const { data } = await ApiService.put(`/users/${user.usr_id}`, user)
    return data
  },
  can ({ state }, { action, subject }) {
    const roles = permissions?.[subject]?.[action] || []
    return roles.includes(state.user.usr_role)
  },
  canViewPage ({ state }, option) {
    let roles = []
    let page = (typeof option === 'object') ? option.page : option
    const read = permissions?.[page]?.['read'] || []
    const update = permissions?.[page]?.['update'] || []
    roles = [...read, ...update]
    if( option.right ){
      roles = permissions?.[page]?.[option.right] || []
    }

    return roles.includes(state.user.usr_role)
  },
  hasRole ({ state }, role) {
    return role === state.user.usr_role
  },
  buildPermissionsTable ({ commit, state }) {
    const userRole = state.user.usr_role
    const table = {}
    Object.keys(permissions).forEach((subject) => {
      table[subject] = {}
      Object.keys(permissions[subject]).forEach((action) => {
        table[subject][action] = permissions[subject][action].includes(userRole)
      })
    })
    commit('SET_PERMISSIONS_TABLE', table)
  }
}

const getters = {
  accessToken (state) {
    return state.accessToken
  },
  refreshToken (state) {
    return state.refreshToken
  },
  permissions (state) {
    return state.permissions
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
