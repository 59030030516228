import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'

Vue.use(VueApexCharts)

// export default {
//     locales: [{
//         "name": "fr",
//         "options": {
//             "toolbar": {
//             "exportToSVG": "Télécharger vectoriel", "exportToPNG": "Télécharger Image", "exportToCSV": "Télécharger Excel",
//             }
//         }
//     }],
// };
